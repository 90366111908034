<template>
  <v-container
    id="contents"
    fluid
    tag="section"
  >
  <v-overlay :value="overlay" />
  <v-snackbar
      v-model="snackbar"
      :timeout="10000"
      :color="snackbarColor"
    >
    {{ snackbarText }}
    <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </template>
  </v-snackbar>
  <v-dialog
    v-model="deleteConfirm"
    persistent
    scrollable
    max-width="400"
  >
    <v-card>
      <v-card-title>
        Conferma cancellazione
      </v-card-title>
      <v-card-text
        v-html="deleteText"
      />
      <v-card-actions>
        <v-spacer />
         <v-btn
          color="green darken-1"
          @click="deleteUserExec"
        >
          OK
        </v-btn>
        <v-btn
          color="green darken-1"
          @click="deleteConfirm = false"
        >
          Annulla
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
        ref="importdialog"
        v-model="importDialogOpen"
        persistent
        scrollable
        max-width="800"
  >
  <v-card
      elevation="8"
    >
    <v-card-title>
      Importazione soci da file
    </v-card-title>
    <v-card-text>
      Carica un file esportato dal gestionale
      <v-file-input
        ref="importUpload"
        v-model="file"
        label="File importazione"
      />
    </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="green darken-1"
          @click="importUsers()"
        >
          OK
        </v-btn>
        <v-btn
            color="green darken-1"
            @click="importDialogOpen = false"
        >
          Annulla
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
    <UserDialog
      :user="currentuser"
      :dialogTitle="dialogTitle"
      :dialogButtonText="dialogButtonText"
      @customevent="getUsers()"
      @update-snack="updateSnack"
    />
    <v-row
      justify="center"
    >
    <v-spacer />
    <v-col cols="4">
      Utenti sito totali: {{ totalusers }}
    </v-col>
    <v-spacer />
    </v-row>
    <v-row
      justify="center"
    >
    <v-spacer />
    <v-col cols="4">
      Utenti iscritti alla newsletter: {{ newsletterusers }}
    </v-col>
    <v-spacer />
    </v-row>
    <v-row
      justify="center"
    >
      <v-spacer />
      <v-col :cols="$isMobile()? 10 : 4">
      <v-btn
        dark
        class="mx-2"
        small
        @click="addUser()"
        >
        Nuovo
        </v-btn>
        <v-btn
        dark
        class="mx-2"
        small
        @click="importDialogOpen = true"
        >
        Importa
        </v-btn>
        <v-btn
        dark
        class="mx-2"
        small
        @click="exportUsers"
        >
          <span
            v-if="exporting"
            class="custom-loader"
          >
          <v-progress-circular
            indeterminate
            color="primary"
          />
          </span>
          <span v-else>Esporta</span>
        </v-btn>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row
      justify="center"
    >
      <v-col
        :cols="$isMobile()? 12 : 8"
      >
        <v-card
          elevation="10"
        >
        <v-row
          v-show="dataMissing"
          justify="center"
        >
          <v-col
            cols="2"
          >
            <br>
            <br>
            <br>
            <v-progress-circular
              indeterminate
              :size="70"
              :width="7"
            />
            <br>
            <br>
            <br>
          </v-col>
        </v-row>
        <v-data-table
          v-show="!dataMissing"
          elevation="10"
          :headers="headers"
          :items="users"
          item-key="name"
          class="elevation-1"
          :search="search"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            itemsPerPageText: 'Soci per pagina',
            itemsPerPageAllText: 'Tutti i soci',
            itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
            pageText: '',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Filtra..."
              class="mx-4"
              itemsPerPageText="'Utenti per pagina'"
            />
          </template>
          <template v-slot:no-data>
            <v-sheet>
              Nessun utente
            </v-sheet>
          </template>
          <template v-slot:no-results>
            <v-sheet>
              Nessun utente
            </v-sheet>
          </template>
          <template v-slot:item.id="{ item }">
            <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="editUser(item)"
                    >
                      <v-icon small>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Modifica</span>
                </v-tooltip>
            <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteUser(item)"
                    >
                      <v-icon small>
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Elimina</span>
                </v-tooltip>
         </template>
        </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import UserDialog from './UserDialog.vue'
  const config = require('@/config')
  const axios = require('axios')
  export default {
    name: 'Users',
    components: { UserDialog },
    data: () => ({
      dataMissing: true,
      exporting: false,
      users: [],
      search: '',
      totalusers: 0,
      newsletterusers: 0,
      currentuser: {},
      dialogTitle: '',
      dialogButtonText: '',
      importFile: '',
      importDialogOpen: false,
      file: null,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'primary',
      overlay: false,
      emptyUser: {
        code: '',
        email: '',
        username: '',
        password: '',
        password2: '',
        firstname: '',
        lastname: '',
        cardnumber: '',
        membershipdeadline: '2019-12-31',
        anciucard: '',
        type: '',
        state: '',
        fiscalcode: '',
        birthstate: '',
        birthplace: '',
        birthprovince: '',
        birthdate: '1975-01-01',
        address: '',
        postalcode: '',
        city: '',
        province: '',
        phone: '',
        mobile: '',
        workplace: '',
        employeenumber: '',
        workaddress: '',
        workpostalcode: '',
        workphone: '',
        workfax: '',
        administrator: 0,
        editor: 0,
        newsletter: 0,
      },
      deleteConfirm: false,
      deleteText: '',
      deleteId: null,
    }),
    computed: {
      headers () {
        return [
          {
            text: 'Cognome',
            align: 'start',
            sortable: true,
            value: 'lastname',
          },
          {
            text: 'Nome',
            align: 'start',
            sortable: true,
            value: 'firstname',
          },
          {
            text: 'e-mail',
            align: 'start',
            sortable: false,
            value: 'email',
          },
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'role',
          },
          {
            text: '',
            align: 'end',
            sortable: false,
            value: 'id',
          },
        ]
      },
    },
    created: function () {
      this.getUsers()
    },
    methods: {
      exportUsers () {
        var component = this
        component.exporting = true
        var currentJWT = localStorage.getItem(config.authToken)
        axios
          .get(config.backendURL + '/members/export', {
            responseType: 'arraybuffer',
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
              const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', 'ARCUS-sito.xls')
              document.body.appendChild(link)
              link.click()
              component.exporting = false
          })
          .catch(function (response) {
            component.exporting = false
          })
      },
      deleteUser (user) {
        var component = this
        component.deleteConfirm = true
        component.deleteText = 'Sei sicuro di voler rimuovere l\'utente &laquo;' + user.firstname + ' ' + user.lastname + '&raquo;?'
        component.deleteId = user.id
        component.deleteConfirm = true
      },
      deleteUserExec () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        axios
          .delete(config.backendURL + '/members/' + component.deleteId, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            component.deleteConfirm = false
            if (response.data.httpStatus === 200) {
              component.getUsers()
              localStorage.setItem(config.authToken, response.data.jwt)
              component.snackbarText = 'Utente rimosso correttamente'
              component.snackbarColor = 'primary'
              component.snackbar = true
            } else {
              component.snackbarText = response.data.longMessage
              component.snackbarColor = 'error'
              component.snackbar = true
            }
          })
          .catch(function (response) {
            component.deleteConfirm = false
          })
      },
      updateSnack (snack) {
        this.snackbar = snack.snackbar
        this.snackbarText = snack.snackbarText
        this.snackbarColor = snack.snackbarColor
      },
      editUser (user) {
        this.currentuser = user
        this.currentuser.editing = true
        this.dialogTitle = 'Modifica socio'
        this.dialogButtonText = 'Salva'
        this.$store.state.userDialogOpen = true
      },
      addUser () {
        this.currentuser = this.emptyUser
        this.currentuser.editing = false
        this.dialogTitle = 'Nuovo socio'
        this.dialogButtonText = 'Aggiungi'
        this.$store.state.userDialogOpen = true
      },
      importUsers () {
        var component = this
        var formData = new FormData()
        formData.append('file', component.file)
        var currentJWT = localStorage.getItem(config.authToken)
        component.overlay = true
        axios
          .post(config.backendURL + '/members/import', formData, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(function (response) {
            component.overlay = false
            if (response.data.httpStatus === 200) {
              localStorage.setItem(config.authToken, response.data.jwt)
              component.snackbarText = 'Importazione avvenuta correttamente: aggiunti ' + response.data.added.length + ' soci, modificati ' + response.data.updated.length + ' soci, senza username ' + response.data.nousername.length + ' soci'
              component.snackbarColor = 'primary'
              component.snackbar = true
              component.getUsers()
            } else {
              component.snackbarText = response.data.longMessage
              component.snackbarColor = 'error'
              component.snackbar = true
            }
          })
          .catch(function (response) {
            component.overlay = false
          })
        component.importDialogOpen = false
        component.file = null
      },
      getUsers () {
        var component = this
        component.dataMissing = true
        var currentJWT = localStorage.getItem(config.authToken)
        // var payload = jwt.decode(currentJWT)
        axios
          .get(config.backendURL + '/members', {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            component.users = response.data.members
            component.totalusers = component.users.length
            component.newsletterusers = response.data.newsletter
            localStorage.setItem(config.authToken, response.data.jwt)
            component.dataMissing = false
          })
          .catch(function (response) {
            console.log(response)
            component.dataMissing = false
          })
      },
    },
  }
</script>
<style >
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 20px !important;
    text-transform: uppercase;
    font-weight: 'bold';
  }
</style>
