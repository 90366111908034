<template>
    <v-dialog
        v-model="$store.state.userDialogOpen"
        scrollable
        persistent
        max-width="800"
    >
    <v-card
        elevation="10"
    >
        <v-card-title class="headline">
          <br>
          <br>
            {{ dialogTitle }}
        </v-card-title>
        <v-card-text>
        <v-text-field
            id="code"
            v-model="user.code"
            type="text"
            outlined
            placeholder="Codice gestionale"
            label="Codice gestionale"
          />
        <v-text-field
            id="firstname"
            v-model="user.firstname"
            :error-messages="firstnameErrors"
            type="text"
            required
            outlined
            placeholder="Nome"
            label="Nome"
            @input="$v.user.firstname.$touch()"
            @blur="$v.user.firstname.$touch()"
        />
        <v-text-field
            id="lastname"
            v-model="user.lastname"
            :error-messages="lastnameErrors"
            type="text"
            required
            outlined
            placeholder="Cognome"
            label="Cognome"
            @input="$v.user.lastname.$touch()"
            @blur="$v.user.lastname.$touch()"
        />
        <v-checkbox
          v-model="user.administrator"
          label="Amministratore"
          @change="user.administrator ? user.editor = false : ''"
        />
        <v-checkbox
          v-model="user.editor"
          label="Redattore"
          @change="user.editor ? user.administrator = false : ''"
        />
        <br>
        <v-text-field
            id="email"
            v-model="user.email"
            :error-messages="emailErrors"
            type="email"
            required
            outlined
            placeholder="email"
            label="email"
            @input="$v.user.email.$touch()"
            @blur="$v.user.email.$touch()"
        />
        <v-checkbox
          v-model="user.newsletter"
          label="Iscritto alla newsletter"
        />
        <br>
        <v-text-field
            id="username"
            v-model="user.username"
            :error-messages="usernameErrors"
            type="email"
            required
            outlined
            placeholder="Username"
            label="Username"
            @input="$v.user.username.$touch()"
            @blur="$v.user.username.$touch()"
        />
        <v-text-field
            v-show="showPassword"
            id="password"
            v-model="user.password"
            :error-messages="passwordErrors"
            type="password"
            required
            outlined
            placeholder="Password"
            label="Password"
            @input="$v.user.password.$touch()"
            @blur="$v.user.password.$touch()"
        />
        <v-text-field
            v-show="showPassword"
            id="password2"
            v-model="user.password2"
            :error-messages="password2Errors"
            type="password"
            required
            outlined
            placeholder="Password (conferma)"
            label="Password (conferma)"
            @input="$v.user.password2.$touch()"
            @blur="$v.user.password2.$touch()"
        />
        <v-text-field
            id="cardnumber"
            v-model="user.cardnumber"
            type="text"
            outlined
            placeholder="Tessera ARCUS"
            label="Tessera ARCUS"
        />
        <v-text-field
            id="state"
            v-model="user.state"
            type="text"
            outlined
            placeholder="Stato"
            label="Stato"
        />
        <v-text-field
            id="type"
            v-model="user.type"
            type="text"
            outlined
            placeholder="Qualifica"
            label="Qualifica"
        />
        Scadenza iscrizione: <span v-if="humanMembershipDeadline"> {{ humanMembershipDeadline }} </span><span v-else> Non presente </span>
        <v-dialog
          v-model="deadlineDialog"
          width="400"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
            <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </template>
          <v-date-picker
            v-model="user.membershipdeadline"
            width="400"
            locale="it-it"
            @input="deadlineDialog = false"
          />
        </v-dialog>
        <br>
        <br>
        <v-text-field
            id="anciucard"
            v-model="user.anciucard"
            type="text"
            outlined
            placeholder="Tessera ANCIU"
            label="Tessera ANCIU"
        />
        <v-text-field
            id="fiscalcode"
            v-model="user.fiscalcode"
            type="text"
            outlined
            placeholder="Codice fiscale"
            label="Codice fiscale"
        />
        <v-text-field
            id="birthstate"
            v-model="user.birthstate"
            type="text"
            outlined
            placeholder="Nazione di nascita"
            label="Nazione di nascita"
        />
        <v-text-field
            id="birthplace"
            v-model="user.birthplace"
            type="text"
            outlined
            placeholder="Luogo di nascita"
            label="Luogo di nascita"
        />
        <v-text-field
            id="birthprovince"
            v-model="user.birthprovince"
            type="text"
            outlined
            placeholder="Provincia di nascita"
            label="Provincia di nascita"
        />
        Data di nascita: <span v-if="humanBirthdate"> {{ humanBirthdate }} </span><span v-else> Non presente </span>
        <v-dialog
          v-model="birthdateDialog"
          width="400"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
            <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </template>
          <v-date-picker
            v-model="user.birthdate"
            width="400"
            locale="it-it"
            @input="birthdateDialog = false"
          />
        </v-dialog>
        <br>
        <br>
        <v-text-field
            id="address"
            v-model="user.address"
            type="text"
            outlined
            placeholder="Indirizzo"
            label="Indirizzo"
        />
        <v-text-field
            id="postalcode"
            v-model="user.postalcode"
            type="text"
            outlined
            placeholder="CAP"
            label="CAP"
        />
        <v-text-field
            id="city"
            v-model="user.city"
            type="text"
            outlined
            placeholder="Citta"
            label="Citta"
        />
        <v-text-field
            id="province"
            v-model="user.province"
            type="text"
            outlined
            placeholder="Provincia"
            label="Provincia"
        />
        <v-text-field
            id="phone"
            v-model="user.phone"
            type="text"
            outlined
            placeholder="Telefono"
            label="Telefono"
        />
        <v-text-field
            id="mobile"
            v-model="user.mobile"
            type="text"
            outlined
            placeholder="Cellulare"
            label="Cellulare"
        />
        <v-text-field
            id="workplace"
            v-model="user.workplace"
            type="text"
            outlined
            placeholder="Sede lavoro"
            label="Sede lavoro"
        />
        <v-text-field
            id="workaddress"
            v-model="user.workaddress"
            type="text"
            outlined
            placeholder="Indirizzo lavoro"
            label="Indirizzo lavoro"
        />
        <v-text-field
            id="workpostalcode"
            v-model="user.workpostalcode"
            type="text"
            outlined
            placeholder="CAP lavoro"
            label="CAP lavoro"
        />
        <v-text-field
            id="workphone"
            v-model="user.workphone"
            type="text"
            outlined
            placeholder="Telefono lavoro"
            label="Telefono lavoro"
        />
        <v-text-field
            id="workfax"
            v-model="user.workfax"
            type="text"
            outlined
            placeholder="FAX lavoro"
            label="FAX lavoro"
        />
        <v-text-field
            id="employeenumber"
            v-model="user.employeenumber"
            type="text"
            outlined
            placeholder="Matricola"
            label="Matricola"
        />
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
            color="green darken-1"
            :disabled="buttonDisabled"
            @click="onSubmit()"
            >
            {{ dialogButtonText }}
            </v-btn>
            <v-btn
                color="green darken-1"
                @click="$store.state.userDialogOpen = false; $emit('customevent', user)"
            >
            Annulla
            </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
</template>
<script>
  import { validationMixin } from 'vuelidate'
  import { required, email } from 'vuelidate/lib/validators'
  const dateFormat = require('dateformat')
  const axios = require('axios')
  const config = require('@/config')
  dateFormat.i18n = {
    dayNames: [
      'Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab',
      'Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato',
    ],
    monthNames: [
      'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic',
      'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre',
    ],
    timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM',
    ],
  }
  export default {
    name: 'UserDialog',
    components: {},
    mixins: [validationMixin],
    props: {
      user: { type: Object, default: null },
      dialogTitle: { type: String, default: '' },
      dialogButtonText: { type: String, default: '' },
    },
    validations: {
      user: {
        title: { required },
        firstname: { required },
        lastname: { required },
        password: { required },
        password2: { required },
        email: { required, email },
        username: { required, email },
      },
    },
    data: () => ({
      // buttonDisabled: true,
      deadlineDialog: false,
      birthdateDialog: false,
      snack: {
        snackbar: false,
        snackbarText: '',
        snackbarColor: 'primary',
      },
    }),
    computed: {
      localdialogOpen: {
        get: function () {
          return this.$store.state.userDialogOpen
        },
      },
      showPassword: {
        get: function () {
          return this.externalUser()
        },
      },
      humanMembershipDeadline: {
        get: function () {
          return this.user.membershipdeadline ? dateFormat(new Date(this.user.membershipdeadline), 'd mmmm yyyy') : ''
        },
        set: function () {
        },
      },
      humanBirthdate: {
        get: function () {
          return this.user.birthdate ? dateFormat(new Date(this.user.birthdate), 'd mmmm yyyy') : ''
        },
        set: function () {
        },
      },
      firstnameErrors: {
        get: function () {
          const errors = []
          if (!this.$v.user.firstname.$dirty) return errors
          !this.$v.user.firstname.required && errors.push('Nome necessario')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
      lastnameErrors: {
        get: function () {
          const errors = []
          if (!this.$v.user.lastname.$dirty) return errors
          !this.$v.user.lastname.required && errors.push('Cognome necessario')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
      passwordErrors: {
        get: function () {
          const errors = []
          if (!this.$v.user.password.$dirty) return errors
          !this.$v.user.password.required && this.externalUser() && !this.editing() && errors.push('Password necessaria')
          !this.$v.user.password.required && this.externalUser() && this.user.password !== this.user.password2 && errors.push('Le password non coincidono')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
      password2Errors: {
        get: function () {
          const errors = []
          if (!this.$v.user.password2.$dirty) return errors
          !this.$v.user.password2.required && this.externalUser() && this.user.password !== this.user.password2 && errors.push('Le password non coincidono')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
      emailErrors: {
        get: function () {
          const errors = []
          if (!this.$v.user.email.$dirty) return errors
          !this.$v.user.email.required && errors.push('Email necessaria')
          !this.$v.user.email.email && errors.push('Email non valida')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
      usernameErrors: {
        get: function () {
          const errors = []
          if (!this.$v.user.username.$dirty) return errors
          !this.$v.user.username.required && errors.push('Username necessario')
          !this.$v.user.username.email && errors.push('Username non valido (email)')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
    },
    watch: {
      localdialogOpen: function (val) {
        this.checkDialogErrors()
      },
    },
    created: function () {
      this.checkDialogErrors()
    },
    methods: {
      editing () {
        if (!this.user) {
          return true
        }
        return this.user.editing
      },
      externalUser () {
        var internalemail = '@UNIMI.IT'
        var studentemail = '@STUDENTI.UNIMI.IT'
        if (!this.user || !this.user.username) {
          return true
        }
        if ((this.user.username.length - this.user.username.toUpperCase().search(internalemail)) !== internalemail.length &&
          (this.user.username.length - this.user.username.toUpperCase().search(studentemail)) !== studentemail.length) {
          return true
        } else {
          return false
        }
      },
      checkDialogErrors () {
        if (
          this.$v.user.firstname.required &&
          this.$v.user.lastname.required &&
          this.$v.user.email.required &&
          this.$v.user.username.required &&
          this.$v.user.email.email &&
          this.$v.user.username.email &&
          (
            (this.$v.user.password === this.$v.user.password2 && this.$v.user.password.required && this.user.editing) ||
            (this.user.editing && this.user.password === this.user.password2 && this.user.password === '') ||
            (this.user.password === this.user.password2 && this.user.password !== '') ||
            !this.externalUser()
          )
        ) {
          this.buttonDisabled = false
        } else {
          this.buttonDisabled = true
        }
      },
      onSubmit () {
        var component = this
        var tosubmit = component.user
        var currentJWT = localStorage.getItem(config.authToken)
        if (component.user.id) {
          axios
            .put(config.backendURL + '/members', tosubmit, {
              headers: {
                authorization: 'Bearer ' + currentJWT,
              },
            })
            .then(function (response) {
              if (response.data.httpStatus === 200) {
                component.snack.snackbarText = 'Utente salvato correttamente'
                component.snack.snackbarColor = 'primary'
                component.snack.snackbar = true
                component.$emit('update-snack', component.snack)
                component.$emit('customevent', tosubmit)
              } else {
                component.snack.snackbarText = response.data.longMessage
                component.snack.snackbarColor = 'error'
                component.snack.snackbar = true
                component.$emit('update-snack', component.snack)
              }
            })
            .catch(function (response) {
              console.log(response)
            })
        } else {
          axios
            .post(config.backendURL + '/members', component.user, {
              headers: {
                authorization: 'Bearer ' + currentJWT,
              },
            })
            .then(function (response) {
              if (response.data.httpStatus === 200) {
                localStorage.setItem(config.authToken, response.data.jwt)
                component.snack.snackbarText = 'Utente creato correttamente'
                component.snack.snackbarColor = 'primary'
                component.snack.snackbar = true
                component.$emit('update-snack', component.snack)
                component.$emit('customevent', component.user)
                component.getContents()
              } else {
                component.snack.snackbarText = response.data.longMessage
                component.snack.snackbarColor = 'error'
                component.snack.snackbar = true
                component.$emit('update-snack', component.snack)
              }
            })
            .catch(function (response) {
              console.log(response)
            })
        }
        this.$store.state.userDialogOpen = false
      },
    },
  }
</script>
